import type { UserAddressApiDto } from "@9amhealth/openapi";
import {
  AutoForm,
  AutoFormAutocompleteField,
  AutoFormInputField,
  Button
} from "@9amhealth/shared";
import styled from "@emotion/styled";
import type { CSSProperties, FC, ReactNode } from "react";
import React, { useEffect, useMemo } from "react";
import { Item } from "react-stately";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import { stateCodeToNameMap } from "src/constants/stateCodeToNameMap";
import { getAvailableStates } from "src/lib/getAvailableStates";
import parseInputData from "src/lib/parseInputData";
import translate from "src/lib/translate";
import ShipmentAddressCubit from "src/state/ShipmentAddressCubit/ShipmentAddressCubit";
import { ShipmentAddressSavingFailureState } from "src/state/ShipmentAddressCubit/ShipmentAddressState";
import { useBloc } from "src/state/state";
import UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { TranslationKey } from "src/types/translationKey";
import { Pad } from "src/ui/styled/Pad";
import { z } from "zod";
import Loader from "../Loader/Loader";
import ErrorBox from "../StyledComponents/ErrorBox";
import Translate from "../Translate/Translate";

const Wrapper = styled.div<{ isShippingUpdate?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  max-width: var(--section-max-width, 100%);
  margin: 0 auto;
  padding: ${({ isShippingUpdate }): string =>
    isShippingUpdate ? "0" : "0 var(--space-md)"};
  box-sizing: border-box;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;

  @media screen and (min-width: 960px) {
    flex-direction: row;
    justify-content: none;
  }
`;

const states = getAvailableStates().map((state) => state.id);
export const Schema = z.object({
  firstName: z.string({
    required_error: (
      <Translate msg="first_name_required" noWrap />
    ) as unknown as string,
    invalid_type_error: (
      <Translate msg="first_name_required" noWrap />
    ) as unknown as string
  }),
  lastName: z.string({
    required_error: (
      <Translate msg="last_name_required" noWrap />
    ) as unknown as string,
    invalid_type_error: (
      <Translate msg="last_name_required" noWrap />
    ) as unknown as string
  }),
  street: z.string({
    required_error: (
      <Translate msg="street_address_required" noWrap />
    ) as unknown as string,
    invalid_type_error: (
      <Translate msg="street_address_required" noWrap />
    ) as unknown as string
  }),
  aptSuite: z.string().optional().nullable(),
  city: z.string({
    required_error: (
      <Translate msg="city_required" noWrap />
    ) as unknown as string,
    invalid_type_error: (
      <Translate msg="city_required" noWrap />
    ) as unknown as string
  }),
  state: z.enum([states[0], ...states.slice(1)], {
    message: (<Translate msg="state_required" noWrap />) as unknown as string,
    required_error: (
      <Translate msg="state_required" noWrap />
    ) as unknown as string
  }),
  zip: z
    .string({
      required_error: (
        <Translate msg="zip_required" noWrap />
      ) as unknown as string,
      invalid_type_error: (
        <Translate msg="zip_required" noWrap />
      ) as unknown as string
    })
    .length(5, translate("invalid_zip_code"))
});

type FormValues = z.infer<typeof Schema>;

interface Props {
  onSuccess: (data: UserAddressApiDto) => Promise<void> | void;
  shippingBloc?: ShipmentAddressCubit;
  onSubmitStart?: () => void;
  onValidationEnd?: () => void;
  loadingElement?: ReactNode;
  formClass?: string;
  loading?: boolean;
  isShippingUpdate?: boolean;
}

const ShippingForm: FC<Props> = (props) => {
  const SchemaRef = Schema;
  const [loading, setLoading] = React.useState(false);
  const [formError, setFormError] = React.useState<string | undefined>();
  const [, { checkZipCode }] = useBloc(UserPreferencesCubit);

  const shippingBloc = useMemo(
    () => props.shippingBloc ?? new ShipmentAddressCubit(),
    []
  );
  const [addressState, { setShipmentAddress, getShipmentAddress }] = useBloc(
    ShipmentAddressCubit,
    {
      create: () => shippingBloc
    }
  );

  useEffect((): void => {
    setLoading(true);
    void getShipmentAddress().then(() => {
      setLoading(false);
    });
  }, []);
  const initialAddress = useMemo(() => addressState.address, [addressState]);

  const cleanFormData = (
    formData?: Partial<FormValues>
  ): Partial<FormValues> => {
    const data: Record<string, number | string | null | undefined> =
      formData ?? {};

    for (const key in data) {
      const value = data[key];
      if (typeof value === "string") {
        data[key] = value.trim();

        if (key === "firstName" || key === "lastName") {
          data[key] = parseInputData("name", value);
        }
      }
    }

    return data;
  };

  const availableStates = getAvailableStates();

  const submitAddress = (rawData: FormValues): void => {
    setFormError(undefined);
    props.onSubmitStart?.();
    const data = cleanFormData(rawData);
    void checkZipCode(rawData.zip).then((zipData) => {
      if (zipData) {
        void setShipmentAddress(data as UserAddressApiDto, async () => {
          void props.onSuccess(data as UserAddressApiDto);
        });
      } else {
        props.onValidationEnd?.();
        setFormError("invalid_zip_code");
      }
    });
  };

  return (
    <div
      style={
        {
          height: "610px",
          "--section-max-width": `${APP_CONTENT_WIDTH}px`
        } as CSSProperties
      }
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%"
          }}
        >
          <Loader active={true}></Loader>
        </div>
      ) : (
        <>
          <AutoForm
            schema={SchemaRef}
            initialValue={{
              firstName: initialAddress.firstName,
              lastName: initialAddress.lastName,
              street: initialAddress.street,
              aptSuite: initialAddress.aptSuite,
              city: initialAddress.city,
              state: availableStates.find(
                (state) => state.name === initialAddress.state
              )?.name,
              zip: initialAddress.zip
            }}
            onSubmit={(data) => submitAddress(data)}
          >
            <Wrapper isShippingUpdate={props.isShippingUpdate}>
              <Group style={{ rowGap: "1em" }}>
                <AutoFormInputField
                  style={{ width: "100%" }}
                  name="firstName"
                  label={translate("first_name")}
                  required={true}
                />

                <AutoFormInputField
                  style={{ width: "100%" }}
                  name="lastName"
                  label={translate("last_name")}
                  required={true}
                />
              </Group>

              <AutoFormInputField
                name="street"
                label={translate("street_address")}
                required={true}
              />

              <AutoFormInputField
                name="aptSuite"
                label={translate("aptSuite")}
              />

              <AutoFormInputField
                name="city"
                label={translate("city")}
                required={true}
              />

              <Group>
                <div style={{ width: "100%" }}>
                  <AutoFormAutocompleteField
                    defaultItems={availableStates}
                    defaultInputValue={
                      initialAddress.state
                        ? stateCodeToNameMap[initialAddress.state]
                        : undefined
                    }
                    name="state"
                    label={translate("state")}
                    isRequired={true}
                  >
                    {(item) => (
                      <Item key={item.id}>{item.label}</Item>
                    )}
                  </AutoFormAutocompleteField>
                </div>

                <AutoFormInputField
                  style={{ width: "100%" }}
                  name="zip"
                  type="num"
                  mask="00000"
                  label={translate("zip")}
                  required={true}
                />
              </Group>
            </Wrapper>

            <nine-spacer></nine-spacer>
            <Button
              disabled={props.loading}
              type="submit"
              theme="sunrise"
              style={{ margin: "auto" }}
            >
              <Translate msg={props.isShippingUpdate ? "save" : "continue"} />
            </Button>
          </AutoForm>

          <Pad>
            {(addressState instanceof ShipmentAddressSavingFailureState ||
              formError) && (
              <>
                <nine-spacer s="md"></nine-spacer>
                <ErrorBox data-severity="error">
                  <Translate
                    msg={(formError as TranslationKey | undefined) ?? "error_generic"}
                  />
                </ErrorBox>
              </>
            )}
          </Pad>
        </>
      )}
    </div>
  );
};

export default ShippingForm;
